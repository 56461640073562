import { ThemeProvider } from "styled-components/macro";
import { BrowserRouter } from "react-router-dom";
import React from "react";

import darkTheme from "./styles/themes/darkTheme.json";
import GlobalCSS from "./styles/GlobalCSS";
import ResetCSS from "./styles/ResetCSS";
import Router from "./Router";

import "./styles/icomoon/style.css";

const App = () => (
  <ThemeProvider theme={darkTheme}>
    <GlobalCSS />
    <ResetCSS />
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  </ThemeProvider>
);

export default App;
