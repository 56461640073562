import styled from "styled-components/macro";
import { responsiveJSX } from "../constants/responsive";
import Tabs from "./Tabs";

const Header = () => (
  <Wrap>
    <Tabs />
  </Wrap>
);

export default Header;

const Wrap = styled(responsiveJSX.Div)`
  box-shadow: 0px 5px 9px rgb(0 0 0 / 29%);
  width: 100%;
  z-index: 1;

  &.is-device {
    position: sticky;
    top: 0;
    left: 0;
  }
`;
