import { Routes, Route } from "react-router-dom";
import styled from "styled-components/macro";
import { useLocation } from "react-router";
import { useEffect } from "react";

import { responsiveJSX } from "./components/constants/responsive";
import ActionButtons from "./components/header/ActionButtons";
import SideMenu from "./components/common/SideMenu";
import NotFound from "./components/pages/NotFound";
import Hello from "./components/pages/hello/Hello";
import About from "./components/pages/about/About";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import routes from "./routes/routes";

const Router = () => {
  const { pathname } = useLocation();
  const isInAboutPage = pathname.replace("/", "") === routes.about.url;

  useEffect(() => {
    const currentRoute = routes[pathname.replace("/", "")]?.title || routes.hello.title;
    document.title = `mariyan_dimitrov${currentRoute}`;
  }, [pathname]);

  return (
    <Wrap>
      <ActionButtons />
      <InnerWrap>
        <MainWrap>
          <Header />
          <PageWrap>
            <Routes>
              <Route path={routes.hello.url} element={<Hello />} />
              <Route path={routes.about.url} element={<About />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </PageWrap>
        </MainWrap>
        {isInAboutPage && <SideMenu />}
      </InnerWrap>
      <Footer />
    </Wrap>
  );
};

export default Router;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const InnerWrap = styled(responsiveJSX.Div)`
  display: flex;
  flex: 1;
  width: 100%;

  &.is-mobile {
    flex-direction: column;
  }
`;

const MainWrap = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${({ theme }) => theme.borderColor};
  width: 100%;
  flex: 1;
`;

const PageWrap = styled(responsiveJSX.Div)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  background-color: ${({ theme }) => theme.surfacePrimary};

  &.is-device {
    padding: 16px;
    flex-direction: column;
    overflow-y: scroll;
  }
`;
