import styled from "styled-components/macro";
import cn from "classnames";

import { isDesktop, responsiveClassName, responsiveJSX } from "../constants/responsive";
import IDE from "./IDE";

const ProjectCard = ({ description, className, isActive, link, title, name, url, ...rest }) => (
  <Wrap className={cn(className, responsiveClassName)} {...rest}>
    <ProjectCardInner
      className={cn({
        "is-active": isActive,
      })}
    >
      <Heading
        className={cn({
          "is-active": isActive,
        })}
      >
        <h3>{name}</h3>
        <span>{title}</span>
      </Heading>
      <Body>
        <IDE
          startingNumberRow={1}
          numberOfBufferRows={isDesktop ? 2 : 0}
          description={description}
        />

        <Anchor className={responsiveClassName} href={url} target="_blank">
          see the project
        </Anchor>

        {link && (
          <LinkAnchor className={responsiveClassName} href={link} target="_blank">
            Try it HERE!
          </LinkAnchor>
        )}
      </Body>
    </ProjectCardInner>
  </Wrap>
);

export default ProjectCard;

const Wrap = styled.div`
  border: 1px solid ${({ theme }) => theme.blue};
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  width: 100%;
`;

const ProjectCardInner = styled(responsiveJSX.Div)`
  position: relative;
  background: ${({ theme }) => theme.surfacePrimary};
  border-radius: 8px;
  padding: 20px;
  height: 315px;

  &.is-device {
    width: 100%;
    height: 230px;
    font-size: 10px;

    &.is-active {
      &:before {
        width: 100%;
        height: 190px;
      }
    }
  }
`;

const Heading = styled(responsiveJSX.Heading)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;

  &.is-active {
    h3 {
      color: ${({ theme }) => theme.accentText};
    }
  }

  &.is-device {
    padding-bottom: 10px;
  }
`;

const Body = styled.div`
  display: flex;
  flex: 1;
`;

const Anchor = styled.a`
  position: absolute;
  left: 0;
  bottom: 40px;
  padding: 0 20px;
  text-decoration: none;
  color: ${({ theme }) => theme.surfaceSecondary};
  background: rgba(244, 190, 82, 0.91);
  width: 100%;

  &.is-mobile {
    bottom: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

const LinkAnchor = styled(Anchor)`
  background: ${({ theme }) => theme.surfaceSecondary};
  color: rgba(244, 190, 82, 0.91);
  width: auto;
  left: unset;
  right: 0;
  transition: background 0.3s ease;

  &:hover {
    background: ${({ theme }) => theme.surfaceTertiary};
  }

  &.is-mobile {
    height: 1.65em;
    border-bottom-left-radius: 0;
  }
`;
