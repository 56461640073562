import styled from "styled-components/macro";
import { useEffect, useState } from "react";
import { format } from "date-fns";

import { responsiveJSX, isDevice } from "../constants/responsive";

const Footer = () => {
  const [date, setDate] = useState(getDate());

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDate(getDate());
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [date]);

  return (
    <Wrap>
      {!isDevice && <Cell>find me in:</Cell>}

      <Cell>
        <a href="https://twitter.com/m_m_dimitrov" rel="noopener noreferrer" target="_blank">
          <i className="icon-twitter" />
        </a>
      </Cell>

      <Cell>
        <a href="https://github.com/mariyan-dimitrov" rel="noopener noreferrer" target="_blank">
          <i className="icon-github" />
        </a>
      </Cell>

      <Cell>
        <a
          href="https://www.linkedin.com/in/mariyan-dimitrov-562b21160/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <i className="icon-linkedin" />
        </a>
      </Cell>

      {!isDevice && <Cell>{date}</Cell>}
    </Wrap>
  );
};

const getDate = () => format(new Date(), "kk:mm:ss dd/MM/yyyy");

export default Footer;

const Wrap = styled(responsiveJSX.Div)`
  position: sticky;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.borderColor};
  background: ${({ theme }) => theme.surfacePrimary};
  z-index: 10;

  &.is-device {
    font-size: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 1px;
  }
`;

const Cell = styled(responsiveJSX.Div)`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-right: 1px solid ${({ theme }) => theme.borderColor};

  &.is-device {
    justify-content: center;

    &:last-child {
      border: none;
    }
  }

  &.is-desktop {
    &:last-child {
      border: none;
      justify-content: flex-end;
      flex: 1;
    }
  }

  i {
    font-size: 22px;
  }

  a {
    line-height: 10px;
    text-decoration: none;
    color: ${({ theme }) => theme.primaryText};
    transition: color 0.4s ease;

    &:hover {
      color: ${({ theme }) => theme.blue};
    }
  }
`;
