import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import { v4 as uuidv4 } from "uuid";
import cn from "classnames";

import useEventListener from "../../hooks/useEventListener";
import routes from "../../../routes/routes";
import { isMobile } from "react-device-detect";

const consoleSuggestion = isMobile ? "Whois" : "whois";
const initialConsoleLines = [`>_`, `// write the code below to continue:`];
const allowedCommands = ["clear", "help"];

const Console = () => {
  const navigate = useNavigate();
  const [consoleLines, setConsoleLines] = useState(initialConsoleLines);
  const [inputFocused, setInputFocused] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [refresh, setRefresh] = useState("");
  const inputHistoryRef = useRef([]);
  const scrollHistoryRef = useRef();
  const inputRef = useRef();

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  useEventListener(() => setInputFocused(true), {
    target: inputRef.current,
    event: "focus",
  });

  useEventListener(() => setInputFocused(false), {
    target: inputRef.current,
    event: "blur",
  });

  useEventListener(
    event => {
      const { code, target } = event;

      if (target === inputRef.current) {
        if (code === "Tab") {
          event.preventDefault();
          setInputValue(consoleSuggestion);
        } else if (code === "Enter") {
          inputHistoryRef.current = [...inputHistoryRef.current, inputValue];

          if (consoleSuggestion !== target.value) {
            if (allowedCommands.includes(target.value)) {
              if (target.value === "clear") {
                setConsoleLines(initialConsoleLines);
              } else if (target.value === "help") {
                setConsoleLines(prevState => [
                  ...prevState,
                  ...[
                    "Portfolio:",
                    "",
                    "",
                    "Commands:",
                    `${consoleSuggestion} - redirects to about page`,
                    "clear - clears the console",
                  ],
                ]);
              }
            } else {
              setConsoleLines(prevState => [
                ...prevState,
                target.value ? `${target.value}: command not found` : "",
              ]);
            }

            setInputValue("");
            scrollHistoryRef.current.scrollTo(0, 100000);
          } else {
            navigate(routes.about.url);
          }
        } else if (code === "ArrowUp") {
          const lastCommand = inputHistoryRef.current.pop();
          lastCommand && setInputValue(lastCommand);
        }
      }
    },
    {
      event: "keydown",
    }
  );

  return (
    <ConsoleWrap>
      <ConsoleHistory ref={scrollHistoryRef}>
        {consoleLines.map(line => (
          <div key={uuidv4()}>{line}</div>
        ))}
      </ConsoleHistory>

      <ConsoleWindow>
        <ConsoleNewLine>{`>`}</ConsoleNewLine>
        <InputWrap>
          {consoleSuggestion.startsWith(inputValue) &&
            inputValue.length <= consoleSuggestion.length && (
              <ConsoleSuggestion>{consoleSuggestion}</ConsoleSuggestion>
            )}
          <Input
            spellcheck="false"
            ref={el => {
              if (inputRef) {
                inputRef.current = el;
                !refresh && setRefresh(uuidv4());
              }
            }}
            value={inputValue}
            onChange={e => {
              if (e.target.value.length <= 20) {
                setInputValue(e.target.value);
              }
            }}
          />
          <Caret
            className={cn({ "is-focused": inputFocused })}
            style={{
              marginLeft: `${inputValue.length}ch`,
            }}
          />
        </InputWrap>
      </ConsoleWindow>
    </ConsoleWrap>
  );
};

export default Console;

const ConsoleWrap = styled.div`
  color: ${({ theme }) => theme.titleColor};
`;

const ConsoleHistory = styled.div`
  margin-bottom: 10px;
  max-height: 200px;
  height: 1000px;
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ConsoleWindow = styled.div`
  display: flex;
  color: ${({ theme }) => theme.purple};
  line-height: 1.1;
  font-size: 1.4em;
`;

const ConsoleNewLine = styled.div`
  margin-right: 10px;
`;

const InputWrap = styled.span`
  position: relative;
`;

const Caret = styled.span`
  display: block;
  content: "_";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 1ch;
  height: 2px;
  background-color: ${({ theme }) => theme.purple};
  pointer-events: none;

  &.is-focused {
    animation: 0.5s linear blink infinite;
  }

  @keyframes blink {
    50% {
      background-color: ${({ theme }) => theme.purple};
    }

    100% {
      background-color: transparent;
    }
  }
`;

const Input = styled.input`
  outline: none;
  background: none;
  border: none;
  caret-color: transparent;
  color: ${({ theme }) => theme.purple};
  font: inherit;
`;

const ConsoleSuggestion = styled.div`
  position: absolute;
  top: 50%;
  left: 2px;
  transform: translate(0, -50%);
  pointer-events: none;
  color: ${({ theme }) => theme.purple};
  opacity: 0.5;
`;
