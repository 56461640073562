import styled from "styled-components/macro";
import { responsiveJSX } from "../constants/responsive";
import { v4 as uuidv4 } from "uuid";

const DescriptionIDE = ({ elRef, subtitle, textLines = [], className, maxCharsForLine }) => (
  <DescriptionWrap className={className}>
    <DescriptionLining ref={elRef}>
      {subtitle && <P>{subtitle}</P>}

      {textLines.map(textLine => (
        <P key={uuidv4()} dangerouslySetInnerHTML={{ __html: textLine }} />
      ))}
    </DescriptionLining>
  </DescriptionWrap>
);

export default DescriptionIDE;

const DescriptionWrap = styled(responsiveJSX.Div)`
  margin: auto 0;
  flex: 1;
  padding: 3ch;
  border-radius: 8px;

  &.is-mobile {
    padding: 1ch 2ch;
  }
`;

const DescriptionLining = styled.div`
  position: relative;
  overflow: hidden;

  &:after {
    position: absolute;
    top: 2.5ch;
    left: 0;
    height: 100%;
    width: 1px;
    display: block;
    content: "";
    background: ${({ theme }) => theme.borderColor};
  }
`;

const P = styled.p`
  overflow: hidden;
  width: 100%;
`;
