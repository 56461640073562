import styled from "styled-components/macro";
import { useState } from "react";
import cn from "classnames";

import { responsiveJSX } from "../constants/responsive";

const defaultFolderTree = {
  title: "MY INTERESTS",
  items: [
    {
      title: "React",
      color: "warning",
      icon: "icon-folder",
      isExpanded: false,
      isClickable: true,
      items: [
        {
          title: "final-forms-arrays.js",
          icon: "icon-js",
          url: "https://www.npmjs.com/package/react-final-form-arrays",
        },
        {
          title: "styled-components.js",
          icon: "icon-js",
          url: "https://www.npmjs.com/package/styled-components",
        },
        {
          title: "react-final-forms.js",
          icon: "icon-js",
          url: "https://www.npmjs.com/package/react-final-form",
        },
        { title: "Capacitor.js", icon: "icon-js", url: "https://capacitorjs.com/" },
        {
          title: "Firebase.js",
          icon: "icon-js",
          url: "https://firebase.google.com/?gclid=Cj0KCQiA8ICOBhDmARIsAEGI6o2iEi-HqkC8zwFWGilVeF8-8afKy2xPBVD-9Tx4ChRrU2-z-8pU9SAaAsJKEALw_wcB&gclsrc=aw.ds",
        },
        { title: "store-me.js", icon: "icon-js", url: "https://www.npmjs.com/package/store-me" },
        { title: "redux.js", icon: "icon-js", url: "https://www.npmjs.com/package/redux" },
      ],
    },
    {
      title: "Node",
      color: "success",
      icon: "icon-folder",
      items: [
        { title: "gulp.js", icon: "icon-js", url: "https://gulpjs.com/" },
        { title: "webpack.js", icon: "icon-js", url: "https://webpack.js.org/" },
        { title: "puppeteer.js", icon: "icon-js", url: "https://www.npmjs.com/package/puppeteer" },
        { title: "forever.js", icon: "icon-js", url: "https://www.npmjs.com/package/forever" },
        { title: "express.js", icon: "icon-js", url: "https://www.npmjs.com/package/express" },
      ],
    },
    {
      title: "HTML & CSS",
      color: "blue",
      icon: "icon-folder",
      isExpanded: false,
      isClickable: false,
      items: [
        { title: "flexbox.css", icon: "icon-css" },
        { title: "grid.css", icon: "icon-css" },
        { title: "animations.css", icon: "icon-css" },
        { title: "transitions.css", icon: "icon-css" },
      ],
    },
    {
      title: "Free time",
      color: "magenda",
      icon: "icon-folder",
      isClickable: false,
      isExpanded: true,
      items: [
        { title: "read articles.md", icon: "icon-markdown" },
        { title: "play guitar.md", icon: "icon-markdown" },
        { title: "hang out with friends.md", icon: "icon-markdown" },
        { title: "play video games.md", icon: "icon-markdown" },
        { title: "spend time with my dog.md", icon: "icon-markdown" },
        { title: "geeking around.md", icon: "icon-markdown" },
      ],
    },
  ],
};

const SideMenu = () => {
  const [folderTree, setFolderTree] = useState(defaultFolderTree);

  return (
    <Wrap>
      <Title>{folderTree.title}</Title>
      <ul>
        {folderTree.items.map((item, index) => (
          <li key={item.title}>
            <div
              onClick={() =>
                setFolderTree(prevState => {
                  const itemsCopy = [...prevState.items];
                  itemsCopy[index].isExpanded = !itemsCopy[index].isExpanded;

                  return {
                    ...prevState,
                    items: itemsCopy,
                  };
                })
              }
            >
              {item.items && (
                <i
                  className={cn("icon-arrow-right", {
                    "is-expanded": item.isExpanded,
                  })}
                />
              )}
              <i
                className={cn(item.icon, item.color, {
                  "is-alone": !item.items,
                })}
              />
              {item.title}
            </div>

            {item.items && (
              <ul
                className={cn("expandable", {
                  "is-expanded": item.isExpanded,
                })}
              >
                {item.items.map(subItem => {
                  const content = (
                    <>
                      <i className={cn(subItem.icon, item.color)} />
                      {subItem.title}
                    </>
                  );

                  return (
                    <li
                      key={subItem.title}
                      className={cn({ "is-not-clickable": !item.isClickable })}
                    >
                      {subItem.url ? (
                        <a href={subItem.url} rel="noopener noreferrer" target="_blank">
                          {content}
                        </a>
                      ) : (
                        content
                      )}
                    </li>
                  );
                })}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </Wrap>
  );
};

export default SideMenu;

const Wrap = styled(responsiveJSX.Div)`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  width: 100%;
  background-color: ${({ theme }) => theme.surfaceTertiary};
  user-select: none;

  &.is-mobile {
    padding-bottom: 16px;
  }

  .icon-arrow-right {
    transition: transform 0.2s ease;

    &.is-expanded {
      transform: rotate(90deg);
    }
  }

  ul {
    margin-left: 8px;

    &.expandable {
      max-height: 0;
      transition: max-height 0.4s ease;
      overflow: hidden;

      &.is-expanded {
        max-height: 12em;
      }
    }

    &.is-clickable {
      li {
        cursor: pointer;
      }
    }

    li {
      line-height: 28px;
      cursor: pointer;

      &.is-not-clickable {
        cursor: default;
      }

      a {
        text-decoration: none;
        color: ${({ theme }) => theme.primaryText};

        &:hover {
          text-decoration: underline;
        }
      }

      ul {
        margin-left: 40px;
      }

      .warning:before {
        color: ${({ theme }) => theme.warning};
      }

      .success:before {
        color: ${({ theme }) => theme.success};
      }

      .error:before {
        color: ${({ theme }) => theme.error};
      }

      .blue:before {
        color: ${({ theme }) => theme.blue};
      }

      .purple:before {
        color: ${({ theme }) => theme.purple};
      }

      .magenda:before {
        color: ${({ theme }) => theme.magenda};
      }
    }
  }

  i {
    font-size: 16px;
    min-width: 16px;
    display: inline-block;
    margin-right: 8px;

    &.is-alone {
      margin-left: 24px;
    }

    & + i {
      margin-left: 0;
    }
  }
`;

const Title = styled.h4`
  margin: 10px 0 10px 40px;
  color: ${({ theme }) => theme.primaryText};
`;
