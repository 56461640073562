const routes = {
  hello: {
    title: "_hello",
    url: "/",
  },
  about: {
    title: "_about_me",
    url: "about",
  },
};

export default routes;
