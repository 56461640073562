import styled from "styled-components/macro";
import { useLocation } from "react-router";

import routes from "../../routes/routes";

const ActionButtons = () => {
  const { pathname } = useLocation();
  const currentRoute = routes[pathname.replace("/", "")]?.title || "_hello";

  return (
    <Wrap>
      {/* <ButtonsWrap>
        <CircleButton className="btn-close"></CircleButton>
        <CircleButton className="btn-minimize"></CircleButton>
        <CircleButton className="btn-maximize"></CircleButton>
      </ButtonsWrap> */}

      <Title>{`mariyan_dimitrov${currentRoute}`}</Title>
    </Wrap>
  );
};

export default ActionButtons;

const Wrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 40px;
  border-radius: 8px 8px 0px 0px;
`;

// const ButtonsWrap = styled.div`
//   position: absolute;
//   top: 0;
//   left: 0;
//   display: flex;
//   align-items: center;
//   height: 100%;
// `;

// const CircleButton = styled.div`
//   position: relative;
//   border-radius: 50px;
//   width: 10px;
//   height: 10px;
//   margin-left: 12px;
//   cursor: pointer;

//   &:before,
//   &:after {
//     display: block;
//     opacity: 0;
//     content: "";
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transition: opacity 0.3s ease;
//   }

//   &:hover {
//     &:before,
//     &:after {
//       opacity: 1;
//     }
//   }

//   &.btn-close {
//     background-color: ${({ theme }) => theme.error};

//     &:before,
//     &:after {
//       width: 50%;
//       border-radius: 5px;
//       height: 0.5px;
//       background-color: ${({ theme }) => theme.surfaceTertiary};
//     }

//     &:after {
//       transform: translate(-50%, -50%) rotate(-45deg);
//     }

//     &:before {
//       transform: translate(-50%, -50%) rotate(45deg);
//     }
//   }

//   &.btn-minimize {
//     background-color: ${({ theme }) => theme.warning};

//     &:after {
//       transform: translate(-50%, -50%);
//       width: 50%;
//       height: 0.5px;
//       border-radius: 5px;
//       background-color: ${({ theme }) => theme.surfaceTertiary};
//     }
//   }

//   &.btn-maximize {
//     background-color: ${({ theme }) => theme.success};

//     &:after {
//       transform: translate(-50%, -50%);
//       width: 50%;
//       height: 50%;
//       border-radius: 1px;
//       background-color: ${({ theme }) => theme.surfaceTertiary};
//     }

//     &:before {
//       transform: translate(-50%, -50%) rotate(45deg);
//       width: 75%;
//       height: 1px;
//       border-radius: 5px;
//       z-index: 1;
//       background-color: ${({ theme }) => theme.success};
//     }
//   }
// `;

const Title = styled.h6`
  color: ${({ theme }) => theme.primaryText};
  margin: 10px 0;
`;
