import styled from "styled-components/macro";

import { responsiveClassName, responsiveJSX } from "../../constants/responsive";
import Projects from "./Projects";
import Console from "./Console";

const Hello = () => {
  return (
    <Wrap className={responsiveClassName}>
      <Section>
        <Heading>
          <h6>Hello! I am</h6>
          <h1>Mariyan Dimitrov</h1>
          <h4 className="special">Senior front-end developer</h4>
        </Heading>
        <Console />
      </Section>
      <Section>
        <Projects />
      </Section>
    </Wrap>
  );
};

export default Hello;

const Wrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  &.is-device {
    flex-direction: column;
    flex: 1;
    width: 100%;
    overflow: hidden;
  }
`;

const Section = styled(responsiveJSX.Section)`
  display: flex;
  flex-direction: column;

  &.is-desktop {
    flex: 1 0 100%;

    &:first-child {
      margin-right: 80px;
    }
  }

  &.is-device {
    flex: 1 1 50%;
    max-width: 100%;
    width: 100%;
  }

  &.is-mobile {
    &:nth-child(2) {
      margin: 210px 0 130px;
    }
  }

  &.is-tablet {
    margin: 0 auto;
    width: 70%;
  }
`;

const Heading = styled.div`
  .special {
    color: ${({ theme }) => theme.blue};
  }
`;
