import styled from "styled-components/macro";

import { responsiveJSX } from "../../constants/responsive";
import IDE from "../../common/IDE";

function calculateAge(date) {
  const ageDifMs = Date.now() - date;
  const ageDate = new Date(ageDifMs);

  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

const startingNumberRow = 40;
const numberOfBufferRows = 10;
const description = `I have ${calculateAge(new Date("2018"))} years of experience in web
development with projects starting with vanilla JavaScript and jQuery to projects with React.js and Vue.js.
I have a passion to other technologies as Node.js, Express.js or anything related with JavaScript, Browser/Web inner workings.
I like to keep my code clean, well-structured and well-thought.`;

const About = () => (
  <Wrap>
    <IDE
      startingNumberRow={startingNumberRow}
      numberOfBufferRows={numberOfBufferRows}
      description={description}
    />
  </Wrap>
);

export default About;

const Wrap = styled(responsiveJSX.Div)`
  display: flex;
  width: 100%;
  max-width: 60%;
  margin: auto;

  &.is-device {
    max-width: unset;
    font-size: 14px;
  }
`;
