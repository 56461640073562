const spacing = " ";
const firstRow = "/**";
const lastRow = " */";
const begginingOfRow = " *";

const textToTextLines = (text, maximumChars) => {
  const words = text.split(/ |\n/g).filter(Boolean);
  const textLines = [];
  let wordToPassAlong = false;
  let currentLine = 0;

  words.forEach(word => {
    if (!textLines[currentLine]) {
      textLines[currentLine] = `${begginingOfRow}`;
    }

    if (wordToPassAlong) {
      textLines[currentLine] = `${begginingOfRow}${spacing}${wordToPassAlong}`;
      wordToPassAlong = false;
    }

    const newRow = `${textLines[currentLine]}${spacing}${word}`;

    if (newRow.length <= maximumChars) {
      textLines[currentLine] = newRow;
    } else {
      currentLine++;
      wordToPassAlong = word;
    }
  });

  if (wordToPassAlong) {
    textLines[currentLine] = `${begginingOfRow}${spacing}${wordToPassAlong}`;
  }

  return [firstRow, ...textLines, lastRow].map(textLine => textLine.replace(/ /g, "&nbsp;"));
};

export default textToTextLines;
