import { useEffect } from "react";

const useEventListener = (callback, { target = document, event, capture }) => {
  useEffect(() => {
    target.addEventListener(event, callback, capture);

    return () => {
      target.removeEventListener(event, callback, capture);
    };
  }, [callback, event, target, capture]);

  return null;
};

export default useEventListener;
