import { useLocation, useNavigate } from "react-router";
import styled from "styled-components/macro";
import cn from "classnames";

import routes from "../../routes/routes";

const Tabs = () => {
  const { pathname } = useLocation();
  const navigateTo = useNavigate();

  return (
    <Wrap>
      {Object.values(routes).map(({ title, url }) => (
        <Tab
          onClick={() => navigateTo(url)}
          className={cn({
            "is-active": pathname.replace("/", "") === url.replace("/", ""),
          })}
          key={url}
        >
          {title}
        </Tab>
      ))}
      <Tab className="placeholder" />
    </Wrap>
  );
};

export default Tabs;

const Wrap = styled.div`
  display: flex;
  height: 65px;
  width: 100%;
  background-color: ${({ theme }) => theme.surfacePrimary};
`;

const Tab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 46px;
  background-color: ${({ theme }) => theme.surfaceTertiary};
  transition: color 0.4s ease;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.borderColor};
  margin-left: -1px;

  &.is-active {
    border-top-color: ${({ theme }) => theme.error};
    border-bottom-color: transparent;
    background-color: ${({ theme }) => theme.surfacePrimary};
    color: ${({ theme }) => theme.accentText};
    box-shadow: inset 0 2px 0 0 ${({ theme }) => theme.error};
  }

  &:hover {
    color: ${({ theme }) => theme.warning};
  }

  &.placeholder {
    flex: 1;
    border-right: none;
    cursor: auto;
    width: 100%;
  }
`;
