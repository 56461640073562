import { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import cn from "classnames";

import ProjectCard from "../../common/ProjectCard";
import { responsiveJSX } from "../../constants/responsive";

const Projects = () => {
  const [activeProject, setActiveProject] = useState(defaultProjects[0].key);
  const [activeProjectsIndex, setActiveProjectsIndex] = useState(0);
  const [projects, setProjects] = useState(defaultProjects);
  const isInitialProjectsLoaded = useRef(false);
  const inTransitionRef = useRef(false);

  useEffect(() => {
    if (!isInitialProjectsLoaded.current) {
      setProjects(prevState => transformProjectsKeys([...prevState, ...prevState]));
      isInitialProjectsLoaded.current = true;
    }
  }, []);

  useEffect(() => {
    let nextState = [...defaultProjects];
    const projectsToShift = nextState.slice(0, activeProjectsIndex);
    nextState = nextState.filter(project => !projectsToShift.includes(project));

    setProjects(transformProjectsKeys([...nextState, ...defaultProjects, ...projectsToShift]));
  }, [activeProjectsIndex]);

  return (
    <Wrap>
      {projects.map(({ name, title, link, description, url, key }, index) => {
        const selectedProjectIndex = defaultProjects.findIndex(
          project => project.key === activeProject
        );
        const activeProjectIndex = projects.findIndex(project => project.key === activeProject);
        const placeAfterActiveIndex = Math.abs(activeProjectIndex - index);
        const isActive = key === activeProject;

        return (
          <StyledProjectCard
            key={key}
            isActive={isActive}
            className={cn({
              "is-active": isActive,
              "is-hidden-beggining": index < activeProjectIndex,
              "is-hidden-after": placeAfterActiveIndex > 2,
            })}
            onClick={() => {
              if (!inTransitionRef.current) {
                inTransitionRef.current = true;
                setActiveProject(key);
              }
            }}
            onTransitionEnd={() => {
              inTransitionRef.current = false;
              setActiveProjectsIndex(selectedProjectIndex);
            }}
            placeAfterActiveIndex={placeAfterActiveIndex}
            name={name}
            title={title}
            link={link}
            description={description}
            activeProject={activeProject}
            url={url}
          />
        );
      })}
    </Wrap>
  );
};

export default Projects;

const transformProjectsKeys = projects => {
  const uniqueKeys = [];

  return projects.map(project => {
    if (!uniqueKeys.includes(project.url)) {
      uniqueKeys.push(project.url);

      return {
        ...project,
        key: project.url,
      };
    }

    return {
      ...project,
      key: `${project.url}-duplicated`,
    };
  });
};

const defaultProjects = transformProjectsKeys([
  {
    name: "2048",
    title: "React.js",
    link: "https://2048.dimitrov.website/",
    description: `I am recreating a classical 2048 game with the addition of a few extras.`,
    url: "https://github.com/mariyan-dimitrov/2048",
  },
  {
    name: "Crawler",
    title: "Node.js",
    link: "",
    description: `A simple crawler that is scanning imot.bg for new real estates based on search criteria.`,
    url: "https://github.com/mariyan-dimitrov/crawler",
  },
  {
    name: "Snake",
    title: "React.js",
    link: "",
    description: `A simple snake game written with two-dimentional array grid.`,
    url: "https://github.com/mariyan-dimitrov/snake",
  },
  {
    name: "FitnessMe",
    title: "React.js + C#",
    link: "",
    description: `Fitness tracker with charts, translations and color themes.`,
    url: "https://github.com/mariyan-dimitrov/fitness-me",
  },
]);

const Wrap = styled(responsiveJSX.Div)`
  position: relative;
  flex: 1 0 100%;
  width: 100%;

  &.is-desktop {
    min-width: 130px;

    &:before {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -55%);
      display: block;
      position: absolute;
      content: "";
      width: 150%;
      height: 600px;
      background-image: radial-gradient(#79c0ff -25%, #0d1117 55%);
      opacity: 0.5;
      border-radius: 100%;
      z-index: 3;
      pointer-events: none;
    }
  }
`;

const StyledProjectCard = styled(ProjectCard)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -${({ placeAfterActiveIndex }) => placeAfterActiveIndex * 2 + 5}5%)
    scale(${({ placeAfterActiveIndex }) => `0.${9 - placeAfterActiveIndex}0`});
  opacity: ${({ placeAfterActiveIndex }) => (placeAfterActiveIndex === 1 ? 0.9 : 0.5)};
  z-index: ${({ placeAfterActiveIndex }) => 3 - placeAfterActiveIndex};
  cursor: pointer;
  transition: transform 0.4s ease, opacity 0.4s ease;

  &:hover:not(.is-active) {
    transform: translate(-50%, -${({ placeAfterActiveIndex }) => placeAfterActiveIndex * 2 + 5}5%)
      scale(${({ placeAfterActiveIndex }) => `0.${9 - placeAfterActiveIndex}5`});
  }

  &.is-active {
    transform: translate(-50%, -50%) scale(100%);
    z-index: 3;
    opacity: 1;
  }

  &.is-hidden-beggining {
    opacity: 0;
    transform: translate(-50%, -25%) scale(100%);
    pointer-events: none;
  }

  &.is-hidden-after {
    opacity: 0;
    pointer-events: none;
  }
`;
