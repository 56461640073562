import styled from "styled-components/macro";

import { responsiveJSX } from "../constants/responsive";

const LineCounter = ({ from = 34, to = 55, hightlightedRows }) => {
  const allRows = Math.abs(to - from);
  const sizeOfGradient = allRows - hightlightedRows;

  return (
    <Wrap $sizeOfGradient={sizeOfGradient}>
      {[...Array(to - from)].map((line, index) => (
        <div key={from + index}>{from + index}</div>
      ))}
    </Wrap>
  );
};

export default LineCounter;

const Wrap = styled(responsiveJSX.Div)`
  display: flex;
  flex-direction: column;
  position: relative;
  color: ${({ theme }) => theme.titleColor};
  box-shadow: 30px 0px 0px -9.5px ${({ theme }) => theme.borderColor};
  width: 20px;
  margin-right: 20px;

  &.is-mobile {
    box-shadow: none;
    margin-right: 0;
    padding: 1ch 0;
  }

  &:after,
  &:before {
    position: absolute;
    display: block;
    content: "";
    left: 0;
    width: 50px;
    height: ${({ $sizeOfGradient }) => $sizeOfGradient * 1.5}ch;
  }

  &:before {
    top: 0;
    background-image: linear-gradient(
      180deg,
      #0d1117 0%,
      rgba(13, 17, 23, 0.75) 50%,
      rgba(13, 17, 23, 0.75) 80%,
      rgba(13, 17, 23, 0) 100%
    );
  }

  &:after {
    bottom: 0;
    background-image: linear-gradient(
      360deg,
      #0d1117 0%,
      rgba(13, 17, 23, 0.75) 50%,
      rgba(13, 17, 23, 0.75) 80%,
      rgba(13, 17, 23, 0) 100%
    );
  }
`;
