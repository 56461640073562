import { useEffect, useState } from "react";
import styled from "styled-components/macro";

import { responsiveJSX } from "../constants/responsive";
import textToTextLines from "../../utils/textToTextLines";
import DescriptionIDE from "./DescriptionIDE";
import LineCounter from "./LineCounter";

const initialMeasureText = "WWW";
const initialDivHeight = 200;

const IDE = ({ startingNumberRow, numberOfBufferRows, description }) => {
  const [measuredDivHeight, setMeasuredDivHeight] = useState(initialDivHeight);
  const [descriptionWrap, setDescriptionWrap] = useState(false);
  const [measureText, setMeasureText] = useState(initialMeasureText);
  const [maxRowWidth, setMaxRowWidth] = useState(0);
  const [measureDiv, setMeasureDiv] = useState(false);
  const [textLines, setTextLines] = useState([]);

  useEffect(() => {
    if (descriptionWrap) {
      const resizeObserver = new ResizeObserver(() => {
        setMaxRowWidth(descriptionWrap.getBoundingClientRect().width);
        setTextLines([]);
      });

      resizeObserver.observe(descriptionWrap);

      return () => resizeObserver.disconnect();
    }
  }, [description, descriptionWrap]);

  useEffect(() => {
    if (maxRowWidth && measureDiv && !textLines.length) {
      const rowHeight = measureDiv.getBoundingClientRect().height;

      if (measuredDivHeight < rowHeight) {
        setTextLines(textToTextLines(description, measureText.length - initialMeasureText.length));
      } else {
        setMeasureText(prevMeasureText => `${prevMeasureText}${initialMeasureText}`);
      }
    }
  }, [description, textLines.length, maxRowWidth, measureDiv, measureText, measuredDivHeight]);

  return (
    <Wrap>
      <LineCounter
        from={startingNumberRow}
        to={startingNumberRow + textLines.length + numberOfBufferRows}
        hightlightedRows={textLines.length}
      />

      <DescriptionIDE elRef={el => el && setDescriptionWrap(el)} textLines={textLines} />

      <MeasureDiv
        maxWidth={maxRowWidth}
        ref={el => {
          if (el) {
            const newHeight = el.getBoundingClientRect().height;

            newHeight < measuredDivHeight && setMeasuredDivHeight(newHeight);
            setMeasureDiv(el);
          }
        }}
        dangerouslySetInnerHTML={{ __html: measureText }}
      />
    </Wrap>
  );
};

export default IDE;

const MeasureDiv = styled.div`
  pointer-events: none;
  visibility: hidden;
  position: fixed;
  word-wrap: break-word;
  max-width: ${({ maxWidth }) => Math.floor(maxWidth)}px;
`;

const Wrap = styled(responsiveJSX.Div)`
  display: flex;
  width: 100%;

  &.is-device {
    font-size: 14px;
  }
`;
