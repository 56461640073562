import { isMobile, isMobileOnly, isTablet, isDesktop } from "react-device-detect";
import styled from "styled-components";
import cn from "classnames";

const responsiveClassName = cn({
  "is-mobile": isMobileOnly,
  "is-desktop": isDesktop,
  "is-device": isMobile,
  "is-tablet": isTablet,
});

const responsiveJSX = {
  Section: ({ children, className, ...rest }) => (
    <Section className={cn(className, responsiveClassName)} {...rest}>
      {children}
    </Section>
  ),
  Div: ({ children, className, ...rest }) => (
    <Div className={cn(className, responsiveClassName)} {...rest}>
      {children}
    </Div>
  ),
  Heading: ({ children, className, ...rest }) => (
    <Heading className={cn(className, responsiveClassName)} {...rest}>
      {children}
    </Heading>
  ),
};

const Div = styled.div``;
const Heading = styled.header``;
const Section = styled.section``;

export {
  isMobileOnly as isMobile,
  isMobile as isDevice,
  responsiveClassName,
  responsiveJSX,
  isDesktop,
  isTablet,
};
