import { createGlobalStyle } from "styled-components/macro";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: Fira Code;
    src: url(./fonts/fira-code.ttf);
  }

  *, *:after, *:before {
    box-sizing: border-box;
    user-select: none;
  }

  body {
    height: 100vh;
    color: ${({ theme }) => theme.primaryText};
    background-color: ${({ theme }) => theme.surfacePrimary};
  }

  h1, h2, h3, h4, h5, h6 {
    color: ${({ theme }) => theme.titleColor};
  }

  #root {
    display: flex;
    flex-direction: column;
    font: 16px/1.6 Fira Code;
    height: 100%;

    h1 {
      font-size: 2.2em;
    }

    h2 {
      font-size: 1.8em;
    }

    h3 {
      font-size: 1.4em;
    }

    h4 {
      font-size: 1.2em;
    }

    h5 {
      font-size: 1.1em;
    }

    h5 {
      font-size: 0.8em;
    }

  }
`;

export default GlobalStyle;
